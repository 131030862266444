<template>
    <div>
        <div>
          <b-row class="d-flex justify-content-between">
            <b-col class="d-flex justify-content-start align-items-start">
            <b-nav-item :to="{name: 'operator', params: { prefix: prefix }}"  style="color:rgba(0, 0, 0, 0)">
                <feather-icon size="20"  icon="ChevronLeftIcon"/>
                <span>{{$t('OperatorHome')}}</span>
            </b-nav-item>
            </b-col>      
          </b-row>
          <b-col class="d-flex justify-content-end align-items-end">
                <b-form-group>
                    <b-form-input
                        v-model="searchTermResource"
                        :placeholder=" $t('message.SearchPlaceholder')"
                        type="text"
                        class="d-inline-block"
                        @keyup="filterResource()"
                    />
                </b-form-group>
          </b-col>
          <br>
          <div class="demo-spacing-0">
              <b-alert variant="primary" :show="(showOverlay == false && resources.length == 0) ? true : false">
                  <div class="alert-body">
                      <span>No resources found.</span>
                  </div>
              </b-alert>
          </div>
          <b-row v-for="resource in resourcesFiltered" :key="resource.id">
              <b-col class="sm-6">
              <b-button
              :style="getBackgroundColor(resource.status)"
              class="btn btn-operator"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              :to="{name: 'operator-orders', params: {prefix: prefix, resource_id: resource.preactor_resource_id}}"
              >
                <h1>{{ resource.name }}</h1>
                <p>{{ resource.status }}</p>
              </b-button>
              </b-col>
          </b-row>
        </div>
    </div>
</template>

<script>
import { BCard, BCol, BRow, BButton, BLink, BAlert, BCardText, BNavItem, BFormGroup, BFormInput} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BLink,
    BAlert,
    BCardText,
    BNavItem,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentSite: JSON.parse(localStorage.getItem('currentSite')),
      prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix, 
      resources: [],
      statusList: [],
      showOverlay: true,
      resourcesFiltered: [],
      searchTermResource: localStorage.getItem('searchTermResource') || ''
    }
  },
  created(){
    this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/currently-status?site_guid=${this.currentSite.guid}`)
            .then((response) => { response.data.response.forEach(status => {
                this.statusList.push({text: status.name, value: status.id, color: status.color})
            })
        })

    this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/resource/workcenters?site_guid=${this.currentSite.guid}`)
      .then((response) => { this.showOverlay = false; response.data.response.forEach(resource => {
        this.resources.push(resource)
      })
      this.filterResource()
    }).catch(() => this.showOverlay = false)
  },
  methods: {
    filterResource(){
      localStorage.setItem('searchTermResource', this.searchTermResource)

      if (this.searchTermResource != '')
          return this.resourcesFiltered = this.resources.filter(el => (el.name.toLowerCase().indexOf(this.searchTermResource.toLowerCase()) != -1))
      return this.resourcesFiltered = this.resources
    },
    getBackgroundColor(status){
        const color = this.statusList.find(el => (el.text == status))
        if (color)
            return `height: 210px; background-color:${color.color} !important;`
        return `background-color:secondary`
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>